
















































































import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { APagination, useBlob, useInvoiceOverdue } from "@/hooks";
import { FormValue } from "@/hooks/useInvoiceOverdue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceIcBillingOverdueResponseDto } from "@/models/interface/invoice-overdue";
import { ListContactDataDto } from "@interface/contact-data";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectCustomer,
    SelectMasterType,
  },
})
export default class InvoiceOverdue extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  pagination = {
    limit: DEFAULT_PAGE_SIZE,
    page: FIRST_PAGE,
  };

  loading = {
    find: false,
    download: false,
  };
  filterForm: FormValue = {
    filterBy: "CUSTOM",
    date: [],
    customerName: "",
    customerId: "",
  };
  dataReport: Pagination<InvoiceIcBillingOverdueResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };
  showDatepicker = true;

  columnsTable = [
    {
      title: this.$t("lbl_internal_contract_number"),
      dataIndex: "internalContractNo",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
      width: 75,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 75,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_equipment_service"),
      dataIndex: "name",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "billingNo",
      width: 75,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      width: 100,
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_bast_number"),
      dataIndex: "bastNo",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_contract_period"),
      key: "contractPeriod",
      width: 150,
      scopedSlots: { customRender: "contractPeriod" },
    },
    {
      title: this.$t("lbl_billing_date"),
      dataIndex: "billingDate",
      width: 100,
      scopedSlots: { customRender: "date" },
    },
  ];

  get isCustomFilter(): boolean {
    return (this.filterForm.filterBy || "").toUpperCase() === "CUSTOM";
  }

  validateForm(): void {
    if (this.isCustomFilter && !this.filterForm.date) {
      this.$notification.error({
        message: "Error",
        description: "Please select the data range first",
      });
      return;
    }
    this.pagination.page = FIRST_PAGE;
    const params: RequestQueryParams = this.preFetch();
    this.fetchReport(params);
  }

  fetchReport(params: RequestQueryParams): void {
    const { findAll } = useInvoiceOverdue();
    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  download(): void {
    const { toDownload } = useBlob();
    const { download, mapFormToParamHeader, mapFormToQueryParams } =
      useInvoiceOverdue();
    const params: RequestQueryParams = mapFormToQueryParams({
      customerId: this.filterForm.customerId,
      date: this.filterForm.date,
      filterBy: this.filterForm.filterBy,
    });
    params.params = mapFormToParamHeader({
      customerName: this.filterForm.customerName,
      date: this.filterForm.date,
      filterBy: this.filterForm.filterBy,
    });
    params.limit = this.dataReport.totalElements;
    params.page = 0;

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "invoice_overdue.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeCustomer(e: Option<ListContactDataDto> | undefined): void {
    this.filterForm.customerId = e?.meta?.id || "";
    this.filterForm.customerName = e?.label || "";
  }

  onChangeTable({ current, pageSize }: APagination): void {
    this.pagination.page = current;

    if (this.pagination.limit !== pageSize) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;

    const params: RequestQueryParams = this.preFetch();
    this.fetchReport(params);
  }

  preFetch(): RequestQueryParams {
    const { mapFormToQueryParams } = useInvoiceOverdue();
    const params = mapFormToQueryParams({
      customerId: this.filterForm.customerId,
      date: this.filterForm.date,
      filterBy: this.filterForm.filterBy,
    });

    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    return params;
  }
}
